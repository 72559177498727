.row-button {
  width: 100%;
}

.mssl-sandomain-row {
  margin-bottom: 16px;
  width: 100%;
}

.mssl-sandomain-select {
  width: 67%;
}

.mssl-sandomain-select-custom {
  margin-top: 8px;
}

.mssl-sandomain-button {
  margin-left: 2%;
  width: 31%;
}

.ux-search.disabled input {
  pointer-events: none;
}

.mssl-sandomain-delete-button {
  cursor: pointer;
}

.mssl-sandomain-removed {
  text-decoration: line-through;
  font-style: italic;
}

.mssl-row-button,
.mssl-onetime-row-button {
  color: var(--uxp-boxHighContrast-foregroundColor);
  border-top: 0 !important;
  border-bottom: 0 !important;
  background-color: #e9ecef !important;
  border-right: 0.0625rem solid #d4dbe0 !important;
  border-left: 0.0625rem solid #d4dbe0 !important;
}

.ux-button.mssl-row-button:not(.ux-button-inline) {
  border-radius: 0 !important;
}

.mssl-row-button-no-hover {
  text-decoration: none !important;
  color: var(--uxp-boxHighContrast-foregroundColor) !important;
}
