// Backups page

@import '~@ux/intents/lib/index';

.backups-page-header {
  border-color: $ux-box-borderColor;

  .backups-page-header-tooltip {
    position: relative;
    top: 2px;
  }

  .triple-dot-dropdown {
    position: relative;
    top: -32px;

    .tripledot {
      svg {
        position: relative;
        top: 2px;
      }
    }
  }
}

.backups-datepicker {
  position: relative;
  left: -16px;

  @media (max-width: 767px) {
    left: 0;
  }

  .ux-datepicker {
    width: 100%;
    position: relative !important;

    &.open {
      z-index: 1 !important;

      @media (max-width: 968px) {
        overflow: hidden;
      }
    }

    .ux-datepicker-flyout.single.always-open {
      position: static;
      width: 100%;
      margin-top: 0;
      border: none;
      background-color: unset;

      .ux-datepicker-month {
        width: 100%;
        padding: 0;

        .header {
          margin-top: 0;
        }
      }
    }

    .day-cell {
      padding-top: calc(100% / 7 - 1.4rem);
      margin: 0.7rem;

      &.failed-day {
        .fill {
          border: 1px solid $ux-feedbackCritical-feedbackColor;
          color: $ux-feedbackCritical-onFeedbackColor;
        }

        &.start-date {
          .fill {
            background-color: $ux-feedbackCritical-feedbackColor;
          }
        }
      }

      &.success-day {
        .fill {
          border: 1px solid $ux-actionSecondary-borderColor;
        }

        &.start-date {
          .fill {
            background-color: $ux-actionChosen-backgroundColor;
            color: $ux-actionChosen-foregroundColor;
            border: 0;
          }
        }
      }
    }
  }
}

// temporary; to override a PCX style conflict with .ux-datepicker.open
#datepicker-container {
  .ux-datepicker {
    z-index: 1 !important;
  }
}

.backups-action-button {
  @media (max-width: 767px) {
    margin-top: 1rem;

    .float-right {
      width: 100%;
    }
  }
}

.backups-for-date-dropdown {
  @media (max-width: 767px) {
    margin-top: 2rem;
  }

  .backups-for-date-dropdown-content {
    height: 2.5rem;
    border-color: $ux-actionSecondary-borderColor;
  }

  .form-control {
    cursor: pointer;
    padding: 0;
  }

  &.dropdown-disabled {
    .form-control {
      cursor: initial;
      opacity: 1;

      .uxicon-svg-container {
        display: none;
      }
    }
  }
}

.backup-create-backup-modal,
.backup-restore-backup-modal,
.backup-download-backup-modal {
  .modal-body:first-child {
    padding-left: 0;
    padding-right: 0;
  }

  .modal-footer {
    padding-left: 0;
  }
}

.backup-restore-backup-modal {
  .ux-custom-checkbox {
    .ux-custom-target {
      margin-bottom: 0;
    }
  }
}

.backup-download-backup-modal,
.backup-create-backup-modal {
  .modal-body:first-child {
    padding-bottom: 15px;
  }
}

.backup-create-backup-modal {
  @media (max-width: 767px) {
    position: absolute;
    width: 90% !important;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -5%);
  }
}

.backup-settings {
  position: relative;
  margin-top: 10px;

  .ux-accordion:first-child {
    border-top: 0;
  }

  .ux-slider {
    position: relative;
    left: -20px;

    .ux-slider-labels {
      height: 4rem;

      @media (max-width: 991px) {
        height: 7rem;
      }

      @media (max-width: 767px) {
        height: 8rem;
      }
    }

    .ux-slider-label {
      @media (max-width: 767px) {
        writing-mode: vertical-rl;
      }
    }

    .ux-slider-label:first-child {
      margin-left: 20px;

      @media (max-width: 968px) {
        margin-left: 0;
      }
    }

    .ux-slider-label:last-child {
      width: 99px;
      padding-right: 20px;

      @media (max-width: 968px) {
        width: auto;
        padding-right: 0;
      }
    }
  }

  .panel-collapse[style=''] {
    overflow: visible;
  }

  .backup-frequency-slider-container {
    margin-left: 30px;
    margin-right: 30px;
    width: 100%;

    @media (max-width: 968px) {
      width: 100%;
      height: auto;
      margin-left: 0;
      margin-right: 0;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .backup-content-tree-container {
    position: relative;
  }
}

.backups-settings-widget {
  .panel-title {
    font-size: 16px;
    font-weight: bold !important;
    padding: 16px 8px !important;
  }

  .panel-collapse {
    background-color: $ux-box-backgroundColor;
  }

  .widget-form-group:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  .widget-form-group:last-of-type {
    margin-bottom: 2.5rem;
    font-weight: 200;
  }

  .widget-input-label {
    display: flex;

    & > * {
      margin-right: 0.625rem;
    }

    .form-check-label {
      margin: 0 10px 5px 0;
      padding: 0.625rem;
    }

    .form-check,
    .form-group {
      margin: 0;
    }

    .uxicon-svg-container {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }

  .settings-save-success {
    color: $ux-feedbackHighContrastHighlight-feedbackColor;
  }

  .settings-save-failed {
    color: $ux-feedbackCritical-feedbackColor;
  }
}

.backup-content-container {
  background-color: white;
  border: 1px solid $ux-box-borderColor;
}

.backup-content-item-details {
  @media (max-width: 767px) {
    .col-divider {
      display: none;
    }
  }
}

.backup-content-tree-container {
  width: 100%;
  max-width: 100%;
  overflow: auto;

  @media (max-width: 767px) {
    max-height: 500px;
  }

  .backup-content-tree {
    flex-grow: 2;
    flex-wrap: wrap;
    align-items: stretch;
  }

  .backup-content-item-container {
    background-color: transparent;
    width: 100%;
  }

  .backup-content-ancestor-container {
    position: relative;
    line-height: 2.5rem;

    .backup-content-ancestor {
      padding-left: 0.8rem;

      @media (max-width: 767px) {
        width: 600px;
        max-width: 600px;
      }
    }
  }

  .backup-content-children-container {
    width: 100%;

    @media (max-width: 767px) {
      width: 600px;
      max-width: 600px;
    }

    div.backup-content-children-container {
      width: 100%;
    }
  }

  .backup-content-descendent-container {
    position: relative;
    line-height: 2.5rem;
  }

  .backup-content-descendent-container:last-child {
    border-left: none;
  }

  .backup-content-descendent-container:last-child:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 1rem;
    border-left: 1px dashed;
  }

  .backup-content-descendent:before {
    position: relative;
    margin-left: 0.5rem;
    margin-right: 0.2rem;
    left: -0.25rem;
    top: 6px;
    display: inline-block;
    min-width: 1rem;
    height: 0.625rem;
    content: '';
    border-top: 1px dashed;
  }

  .backup-content-item-content {
    width: 100%;
  }

  .backup-content-item-size {
    width: 100px;
    min-width: 100px;
    line-height: 2.5rem;
  }

  .backup-content-item-checkbox {
    .ux-custom-checkbox {
      position: relative;
      top: 12px;
      left: 14px;

      label {
        margin: 0;
      }
    }
  }

  .backup-content-item-icon {
    svg {
      position: relative;
      top: 7px;
    }

    .spin-center {
      height: 18px;

      .ux-spinner-sm {
        top: 3px;
        left: 3px;
      }
    }

    .backup-content-directory-icon-multiselect {
      .ux-spinner-sm {
        top: 1px;
      }
    }
  }

  .backup-content-item-name {
    flex-grow: 2;
  }

  .backup-content-item-icon:focus,
  .backup-content-item-name:focus {
    outline: none !important;
  }

  .backup-content-item-icon:focus-visible,
  .backup-content-item-name:focus-visible {
    outline: 5px auto $ux-actionChosen-backgroundColor !important;
  }

  .backup-content-item-button-container {
    min-width: 86px;

    .backup-content-item-button {
      min-height: 2rem;
      height: 2rem;
    }
  }

  .backup-content-item-container-hovered {
    position: absolute;
    width: 200%;
    height: 40px;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .backup-content-file {
    overflow-x: hidden;
    white-space: nowrap;
    line-height: 2.5rem;
  }

  .backup-cursor-pointer {
    cursor: pointer;
  }
}

.backups-content-container {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
}

.settings-spinner {
  vertical-align: text-top;
}

.settings-sftp-spinner {
  height: 25rem;
}

.checkbox-group span:after {
  content: ' ';
  display: block !important;
}

// temporary; to override a PCX style conflict with .ux-datepicker.open
#datepicker-container {
  .ux-datepicker {
    z-index: 1 !important;
  }
}

// fun with backups errors
.backups-callout {
  background-color: white;
  padding: 1.25rem;
  margin-top: 0.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;

  .ux-text-title {
    font-size: 1.2rem;
  }

  .ux-button-inline {
    color: $ux-boxLowContrast-foregroundColor !important;
    text-decoration: underline;
  }
}

.backups-callout-error {
  border-left-color: #d9534f;
}
