.my-sites-search-form {
  max-width: 20rem;

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
}

.my-sites-wrapper {
  .lead,
  .site-row {
    display: grid;
    grid-template-columns: min(20rem) repeat(4, 1fr) max(8rem);

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  .lead {
    background-color: $ux-box-backgroundColor;
    border-bottom: 1px solid $ux-boxLowContrast-borderColor;

    @media screen and (min-width: 992px) {
      position: sticky;
      top: 0;
    }

    .uxp-delegation & {
      top: 2.5rem; //Adjust sticky lead when delegation banner is present
    }
  }

  .site-th-btn {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background: transparent;
    cursor: pointer;

    &:hover {
      color: $ux-feedbackHighContrastSuccess-feedbackColor;
    }

    .site-sort-icon {
      transition: all 0.1s linear;
    }

    &.sort-desc {
      .site-sort-icon {
        transform: rotate(180deg);
      }
    }

    &.sort-asc {
      .site-sort-icon {
        transform: rotate(0);
      }
    }
  }

  .site-row {
    border-bottom: 1px solid $ux-boxLowContrast-borderColor;

    &.has-critical {
      .site-domain {
        color: $ux-feedbackHighContrastCritical-feedbackColor;
      }
    }
    &.is-insecure {
      .site-domain {
        color: $ux-controlVoid-foregroundColor;
      }
    }
  }

  .capability-link {
    text-decoration: none;
  }
}

.site-action {
  --ux-1sbfig8: 0.6rem; //Override Menu Item padding
}

.site-domain {
  max-width: 18rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-sites-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;

  &.has-warning,
  &.has-not-active {
    background-color: $ux-feedbackWarning-feedbackColor;
    color: $ux-feedbackHighContrastWarning-feedbackColor;
  }

  &.has-not-entitled {
    background-color: $ux-feedbackNeutral-feedbackColor;
    color: $ux-feedbackHighContrastNeutral-feedbackColor;
  }

  &.has-critical {
    background-color: $ux-feedbackCritical-feedbackColor;
    color: $ux-feedbackHighContrastCritical-feedbackColor;
  }

  &.has-healthy {
    background-color: $ux-feedbackSuccess-feedbackColor;
    color: $ux-feedbackHighContrastSuccess-feedbackColor;
  }

  @media screen and (max-width: 991px) {
    width: 5.5rem;
    height: 1.6rem;
    border-radius: 1.6rem;
    padding: 1px 10px;
    text-transform: capitalize;
    justify-content: flex-start;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.container.my-sites-container {
  @media (min-width: 992px) and (max-width: 1200px) {
    max-width: 100%;
  }
}
