@use '@ux/intents/lib/index' as ux;

.firewall-widget-container {
  background-color: ux.$ux-box-backgroundColor;
  min-width: 20rem;

  .widget-ok {
    color: ux.$ux-feedbackHighContrastSuccess-feedbackColor;
  }

  .widget-not-ok {
    color: ux.$ux-actionCritical-borderColor;
  }
}

.firewall-widget-https {
  .firewall-https-protocol-line {
    border-top: 2px dashed ux.$ux-boxLowContrast-borderColor;
    flex: 1;
  }

  .protocol-http {
    border-color: ux.$ux-actionCriticalHovered-borderColor;

    .form-control {
      border-color: ux.$ux-actionCriticalHovered-borderColor;
    }
  }

  .firewall-https-protocol-selector {
    width: 6rem;
    margin: 0.5rem;
  }
}
