// WSS Setup Modal
.wss-setup-progress-bar-container {
  text-align: left;
}

.wss-setup-progress-bar {
  margin-top: 40px;
}

.wss-setup-progress-percent {
  margin-top: 8px;
}

.wss-setup-progress-please-wait {
  margin-top: 16px;
}

.wss-setup-progress-bar-container .ux-progress-area {
  border-radius: 1rem;
}

.wss-setup-progress-bar-container .ux-progress-fill {
  border-radius: 1rem;
  -webkit-transition: width 0.3s ease-in-out; /* For Safari 3.1 to 6.0 */
  transition: width 0.3s ease-in-out;
}

.wss-setup-success-site-added {
  width: 95%;
  text-align: left;
  margin-bottom: 40px;
}

.wss-setup-result-row {
  width: 95%;
  margin-bottom: 16px;
}

.wss-setup-result-icon {
  font-size: 16px;
  float: left;
  margin-right: 8px;
  text-align: left;
}

.wss-setup-result-text {
  float: left;
  width: 85%;
}

.wss-setup-result-text-header {
  font-weight: $ux-textHeading-fontWeight;
  font-size: 1.5rem;
  text-align: left;
}

.wss-setup-result-text-description {
  color: $ux-controlVoid-foregroundColor;
  font-size: 1rem;
  text-align: left;
  margin-top: 4px;
}

.wss-setup-result-text-color-pass {
  color: $ux-boxLowContrast-foregroundColor;
}

.wss-setup-result-text-color-warning {
  color: $ux-feedbackWarning-onFeedbackColor;
}

.wss-setup-result-text-color-danger {
  color: $ux-feedbackCritical-onFeedbackColor;
}

.wss-setup-result-text-color-note {
  font-size: 14px;
  color: rgb(
    118,
    118,
    118
  ) !important; // #767676 (should be intents.ux.feedbackHighContrastPassive.feedbackColor)
}

.wss-setup-button-row {
  margin-top: 2.5rem !important;
  text-align: left;
}

.domain-select-block {
  padding-top: 16px;
}

.domain-custom-block {
  padding-top: 32px;
}

.domain-custom-block .custom-control-description {
  color: $ux-controlVoid-foregroundColor;
}

.domain-custom-block [type='radio']:checked ~ span {
  color: $ux-boxHighContrast-foregroundColor !important;
}

.setup-display-checks p {
  margin-bottom: 0.8rem;
}
