// UXCore2 build requires these variables
$uxcore-label: 'gd'; // gd (godaddy) vs pl (private label)
$uxcore-env: 'production'; // Environment
$uxcore-market: 'en-US'; // Which market
$uxcore-region: 'US'; // Which region
$uxcore-palette-build: false; // Is it a palette build (i.e. for a private label / alternate palette)

@import '@ux/intents/lib/index';
@import '@ux/icon/add/index.css';
@import '@ux/icon/addon/index.css';
@import '@ux/icon/alert/index.css';
@import '@ux/icon/archive/index.css';
@import '@ux/icon/arrows-circle/index.css';
@import '@ux/icon/attachment/index.css';
@import '@ux/icon/bell/index.css';
@import '@ux/icon/bookmark/index.css';
@import '@ux/icon/cart/index.css';
@import '@ux/icon/checkbox/index.css';
@import '@ux/icon/checkmark/index.css';
@import '@ux/icon/chevron-left/index.css';
@import '@ux/icon/chevron-right/index.css';
@import '@ux/icon/chevron-dbl-right/index.css';
@import '@ux/icon/chevron-down/index.css';
@import '@ux/icon/chevron-up/index.css';
@import '@ux/icon/clock/index.css';
@import '@ux/icon/circle-filled/index.css';
@import '@ux/icon/comment/index.css';
@import '@ux/icon/copy/index.css';
@import '@ux/icon/dashboard/index.css';
@import '@ux/icon/delete/index.css';
@import '@ux/icon/download/index.css';
@import '@ux/icon/drive/index.css';
@import '@ux/icon/ellipsis/index.css';
@import '@ux/icon/excel/index.css';
@import '@ux/icon/exchange/index.css';
@import '@ux/icon/receipt/index.css';
@import '@ux/icon/filter/index.css';
@import '@ux/icon/folder/index.css';
@import '@ux/icon/folder-open/index.css';
@import '@ux/icon/gd-the-go/index.css';
@import '@ux/icon/help/index.css';
@import '@ux/icon/hide/index.css';
@import '@ux/icon/image/index.css';
@import '@ux/icon/information/index.css';
@import '@ux/icon/lightning-bolt/index.css';
@import '@ux/icon/locked/index.css';
@import '@ux/icon/ok/index.css';
@import '@ux/icon/page/index.css';
@import '@ux/icon/paintbrush/index.css';
@import '@ux/icon/plus/index.css';
@import '@ux/icon/powerpoint/index.css';
@import '@ux/icon/refresh/index.css';
@import '@ux/icon/search/index.css';
@import '@ux/icon/server/index.css';
@import '@ux/icon/settings/index.css';
@import '@ux/icon/settings-filled/index.css';
@import '@ux/icon/shield/index.css';
@import '@ux/icon/shield-check/index.css';
@import '@ux/icon/show/index.css';
@import '@ux/icon/trash/index.css';
@import '@ux/icon/video/index.css';
@import '@ux/icon/website/index.css';
@import '@ux/icon/window-new/index.css';
@import '@ux/icon/word/index.css';
@import '@ux/icon/wordpress/index.css';
@import '@ux/icon/world/index.css';
@import '@ux/icon/chat/index.css';
@import '@ux/icon/cloud-upload/index.css';
@import '@ux/icon/user/index.css';
@import '@ux/icon/star-filled/index.css';
@import '@ux/icon/star/index.css';

@import '@ux/modal/dist/styles.css';
@import '@ux/growl/dist/styles.css';
@import '@ux/checkbox/dist/styles.css';
@import '@ux/radio/dist/styles.css';
@import '@ux/search/dist/styles.css';
@import '@secui/support/dist/styles.css';
@import '@ux/collapsible/dist/styles.css';
@import '@ux/progress-steps/dist/styles.css';
@import '@ux/pagination/dist/styles.css';
@import '@ux/tabs/dist/styles.css';
@import '@ux/toggle/dist/styles.css';
@import '@ux/tooltip/dist/styles.css';
@import '@ux/progress-bar/dist/styles.css';
@import '@ux/disrupt/styles';
@import '@ux/alert/styles';
@import '@ux/box/styles';
@import '@ux/message-overlay/styles';
@import '@ux/spinner/styles';

body.ux-app {
  min-height: 100%;
  padding-bottom: 84px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.form-group {
  margin-bottom: 0;
}

.sec-title-icon {
  display: inline-block;
  vertical-align: middle;
}

.edit-modal .modal-header {
  padding-bottom: 0.5rem;
}

.sec-modal-header-image {
  display: 'block';
  margin-left: 'auto' !important;
  margin-right: 'auto' !important;
}

.row.product-list-item {
  padding-top: 32px;
  padding-bottom: 32px;
  border-top: 1px solid $ux-boxLowContrast-borderColor;
}

.edit-modal {
  h2 {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .modal-footer {
    justify-content: flex-start;
  }
}

.secui-panel {
  border: 1px solid $ux-boxLowContrast-borderColor;

  > header {
    background: $ux-boxLowContrast-backgroundColor;
    padding: 10px 20px;
    line-height: 26px;
  }

  > section {
    padding: 20px;
  }

  .cell-padding {
    padding: 22px;
  }

  // select/input labels
  .custom-control-description {
    font-weight: $ux-textHeading-fontWeight;
  }
}

.pager-container {
  border-top: 1px solid $ux-boxLowContrast-borderColor;
  padding-top: 1rem;
}

.ux-search {
  margin-bottom: 0;
}

.ux-button:not(.ux-button-inline) {
  white-space: normal;
}

.mssl-card-block {
  max-width: 1130px;
}

.mssl-align-left {
  text-align: left;
}

.mssl-align-right {
  text-align: right;
}

.mssl-align-center {
  text-align: center;
}

.mssl-grey-text {
  color: $ux-boxLowContrast-foregroundColor;
}

.active-label,
.pending-label {
  color: $ux-boxLowContrast-foregroundColor;
  margin-bottom: 0;
}

.status {
  color: $ux-boxLowContrast-foregroundColor;
  margin-bottom: 0;
}

.edit-link {
  text-decoration: none;
}

.btn-link {
  padding-left: 0;
  padding-right: 0;
  height: auto;
  min-height: 0;
}

.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  color: $ux-actionHovered-foregroundColor !important;
  background-color: transparent !important;
  text-decoration: underline;
}

.sec-btn {
  min-width: 90px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-clear {
  clear: both;
}

.mssl-domain-header {
  color: $ux-boxLowContrast-foregroundColor;
}

.mssl-subdomain-container {
  margin-top: 30px;
}

.mssl-subdomain-row {
  margin-top: 15px;
}

.mssl-domain-input {
  width: 560px;
}

.mssl-modal-footer {
  padding-top: 16px;
}

.align-items-stretch {
  align-items: stretch !important;
}

.w-100 {
  width: 100%;
}

.text-bold {
  font-weight: bold;
}

.mssl-margin-center {
  margin-left: auto;
  margin-right: auto;
}

.no-domain-buy-btn {
  margin-left: 15px;
}

.mssl-icon {
  vertical-align: baseline;
  margin-right: 0.15rem;
}

.mssl-autorenew-message {
  display: inline-block;
}

.mssl-manage-badge {
  background-color: $ux-figure-figureColor0;
  padding: 4px 9px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: white;
}

.mssl-view-comm-link {
  font-size: 0.9rem;
}

.tooltip-text {
  a:link {
    color: inherit;
  }
}
.tooltip-main {
  max-width: 22rem !important;
}

.w-sm-100 {
  width: 100%;
}

.m-sm-t-1 {
  margin-top: 1rem;
}

.m-b-sm {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .w-sm-100 {
    width: auto;
  }

  .p-l-md-0 {
    padding-left: 0;
  }

  .m-sm-t-1 {
    margin-top: inherit;
  }
}

.loading-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $ux-boxLowContrast-backgroundColor;
  z-index: 5;
  height: 100%;
  width: 100%;
}

.overlay-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  min-height: 80px;
  width: 100%;
}

.custom-control-description {
  font-weight: $ux-textHeading-fontWeight;
}

// MSSL communication page
.ticket-page-container {
  margin-top: 30px;
}

.ticket-page-last-update {
  text-align: center;
  color: var(ux-controlVoid-foregroundColor);
  padding-right: 25px;
  padding-top: 10px;
}

.ticket-page-status-alert {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 90%;
}

.ticket-page-credentials-alert {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 90%;

  /* stylelint-disable selector-class-pattern */
  .ux-alert-critical,
  .ux-alert--critical {
    .ux-button:not(.ux-button-secondary):not(.ux-button-primary):not(
        .ux-button-critical
      ):not([disabled]):hover {
      background-color: $ux-actionCriticalFocused-backgroundColor !important;
      color: white;
      font-weight: bold;
      border-radius: $ux-action-borderRadius;
    }
  }
  /* stylelint-enable selector-class-pattern */
}

.ticket-page-status-alert,
.ticket-page-credentials-alert {
  h2.ux-text-title {
    font-size: 1.2rem;
  }
}

.ticket-page-last-type-container {
  padding-top: 45px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.ticket-page-progress-bar {
  padding-bottom: 45px;
}

.ticket-page-view-cert {
  margin-bottom: 2px;
  min-height: 24px;
}

.ticket-page-message-container,
.ticket-page-message-history-container {
  padding-top: 45px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.ticket-page-message-history-container {
  padding-top: 2px !important;
  button:focus {
    outline: 5px auto $ux-actionChosen-backgroundColor;
  }
  .h3 {
    font-size: 1.4rem;
  }
  .panel {
    border-bottom: 2px solid $ux-actionChosen-backgroundColor;
    border-right: 2px solid $ux-actionChosen-backgroundColor;
  }
  .panel-collapse {
    background-color: white;
  }
  .panel-button {
    padding-top: 0;
    .panel-indicator {
      width: 3rem;
    }
  }
  .panel-collapse {
    border-left: 2px solid $ux-actionChosen-backgroundColor;
  }
  .ux-accordion {
    border-top: 2px solid $ux-actionChosen-backgroundColor;
  }
}
.ticket-page-message-entry {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
}

.ticket-page-message-content-block {
  float: left;
  width: 70%;
  display: block;
}

.ticket-page-message-content-from-default {
  color: $ux-boxLowContrast-foregroundColor;
}

.ticket-page-message-content-from-me {
  color: $ux-feedbackSuccess-onFeedbackColor;
}

.ticket-page-message-content-date {
  float: right;
  width: 30%;
  display: block;
  margin-top: 15px;
  text-align: right;
}

.ticket-page-message-content {
  color: $ux-controlVoid-foregroundColor;
}

.ticket-page-message-form {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 45px;
  padding-top: 20px;
}

.ticket-domains-container {
  overflow-y: auto;
  max-height: 200px;
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid $ux-boxLowContrast-borderColor;
}

.essl-float-left {
  float: left;
}

.essl-float-right {
  float: right;
}

.essl-clear-both {
  clear: both;
}

.essl-h4-domain {
  color: $ux-actionPrimaryFocused-outlineColor;
}

.essl-text-grey {
  color: $ux-feedbackNeutral-onFeedbackColor;
}

.essl-text-yellow {
  color: $ux-feedbackWarning-onFeedbackColor;
}

.essl-card-container {
  margin-bottom: 15px;
}

.essl-bg-white {
  background-color: $ux-box-backgroundColor;
}

.essl-link-no-underline {
  text-decoration: none;
}

.mssl-svg-domain-type-container {
  padding-bottom: 40px;
}

.mssl-domain-type-container {
  padding-left: 20px;
  padding-top: 10px;
}

.mssl-comm-domain {
  padding-bottom: 15px;
}

.mssl-comm-back-btn {
  padding-bottom: 30px;
}

.mssl-onetime-label {
  display: inline-block;
  margin-bottom: 0.25rem;
}

.ux-tabs-wiz-txt {
  max-width: 15em;
}

.ticket-message-row {
  margin-bottom: 30px;
}

.ticket-message-content {
  margin-right: 10px;
  border-radius: 8px;
  padding: 15px 25px;
  max-width: 70%;
  word-wrap: break-word;
}

.ticket-message-date {
  margin-top: 10px;
  font-size: 13px;
  padding: 10px;
  color: $ux-boxLowContrast-foregroundColor;
}

.ticket-message-me {
  background-color: $ux-boxLowContrast-backgroundColor;
}

.ticket-message-open {
  background-color: $ux-actionChosen-backgroundColor;
}

.ticket-message-closed {
  background-color: $ux-boxLowContrast-backgroundColor;
}

.mssl-support-svg-container {
  padding-top: 60px;
  margin-left: 50px;
}

.mssl-support-msg-container {
  margin: 0 50px;
  padding: 1.5rem;
}

// END - MSSL communication page

.panel-button {
  padding: 10px 50px 0 0;
}

.panel-button:after {
  top: 9px;
}

.h-100 {
  min-height: 100vh;
}

.h-full {
  height: 100%;
}

.stretch-overlay > .ux-overlay-wrapper {
  align-items: stretch;
  display: flex;
}

.site-card-dropdown {
  cursor: pointer;

  &:not(.open) > .form-control {
    border-color: $ux-boxLowContrast-borderColor;
  }
}

.mssl-messages-notification {
  position: relative;
  width: 40px;
  margin: 0 auto;

  .mssl-messages-count-wrapper {
    top: -6px;
    right: 3px;
    padding: 2px;
    min-width: 24px;
    height: 24px;
    position: absolute;
    border-radius: 12px;
  }
  .mssl-messages-count {
    line-height: 16px;
    font-size: 12px;
  }
}

.site-card {
  .site-card-product {
    @media (max-width: 991px) {
      border-top: 1px solid $ux-boxLowContrast-borderColor;
      &:last-child {
        border-bottom: 1px solid $ux-boxLowContrast-borderColor;
      }
      .ux-button span.ux-button-text {
        display: none;
      }
    }

    @media (max-width: 767px) {
      &:last-child {
        border-bottom: none;
      }
    }

    .site-card-product-wrapper {
      @media (max-width: 991px) {
        order: -1;
      }
    }
  }
  .site-card-notification {
    a:link {
      color: inherit;
    }
  }
}

.btn-arrow {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='12' viewBox='0 0 16 12' xmlns='http://www.w3.org/2000/svg' fill='%23111'%3E%3Ctitle%3EArrow%3C/title%3E%3Cpath d='M11.879 7.059H0V4.94h11.879L8.348 1.487 9.868 0 16 6l-6.133 6-1.52-1.487L11.88 7.06z'/%3E%3C/svg%3E");
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: inline-block;
  width: 16px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
}

.btn-arrow-left {
  transform: scale(-1);
}

.site-settings-modal {
  .modal-header {
    padding-bottom: 0;
  }
}

.mssl-sub-card {
  .custom-control-indicator {
    top: 8px;
  }
}

.min-h-auto {
  min-height: auto;
}

.leading-0 {
  line-height: 0;
}
.leading-1 {
  line-height: 1;
}

.support-card {
  text-decoration: none;
  border-radius: 4px;

  &:hover {
    border-color: $ux-controlVoid-borderColor;
    text-decoration: none;
  }

  .notification {
    font-size: 1rem;
    padding: 5px;
    position: absolute;
    right: 0;
  }

  @media (min-width: 768px) {
    .notification {
      position: relative;
    }
  }

  .icon {
    svg {
      vertical-align: bottom;
    }
  }

  .arrow-link::after {
    content: '\e477';
    transform: scaleX(1);
    font-size: 2rem;
    font-family: uxfont;
    font-weight: 400;
    color: $ux-actionPrimary-foregroundColor;
    position: absolute;
    top: 0.8rem;
    right: 0;
    line-height: 0;
  }
}

.message-block {
  max-width: 80%;

  .message-block-body {
    border-radius: 16px;
  }

  @media (max-width: 768px) {
    max-width: 80%;
  }
}

@media (min-width: 1720px) {
  .pivot-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
  }
}

.pre-selected label,
.mssl-label {
  font-weight: $ux-textParagraph-fontWeight;
  color: $ux-boxLowContrast-foregroundColor;
}

.pre-highlighted .custom-control-description {
  font-weight: $ux-textHeading-fontWeight !important;
}

.breadcrumb a {
  font-weight: $ux-textParagraph-fontWeight !important;
}

.spinner-wrapper {
  max-width: 190px;
}

.spinner-wrapper .ux-spinner {
  margin: 0 auto;
}

.min-full-footer {
  min-height: calc(100vh - 204px);
}

.sct-wrapper {
  max-height: 562px;
  overflow: auto;
}

.credentials-container {
  .credentials-fields-container {
    border-radius: $ux-action-borderRadius;
    font-size: $ux-text-fontSize;
    font-weight: $ux-text-fontWeight;
    line-height: $ux-text-lineHeight;
    padding: 0.5em;
    margin-top: 15px;
    margin-bottom: 15px;
    color: $ux-boxLowContrast-foregroundColor;
    background-color: $ux-boxLowContrast-backgroundColor;
    border: 1px solid $ux-control-borderColor;

    .req {
      color: $ux-actionCritical-borderColor;
    }
  }

  .has-danger .form-control {
    background-color: $ux-actionCritical-foregroundColor;
    border-color: $ux-actionCriticalFocused-backgroundColor !important;
  }

  .ux-dialog-shell .ux-dialog-content {
    margin: 0;
  }
}

.pivot-spinner-wrapper {
  font-size: 15px;
  height: 49px;
}

.notification-row .ux-alert:nth-child(n + 2) {
  margin-top: 0.5rem;
}

.notification-row h2.ux-text-title {
  font-size: 1.2rem;
}

.inactive-plans-wrapper {
  height: auto;
  overflow: visible;
}

.inactive-plans-wrapper .plans-card:last-child {
  margin-bottom: 0 !important;
}

.inactive-plans-wrapper.collapsed {
  height: 92px;
  overflow: hidden;

  @media (max-width: 767px) {
    height: 160px;
  }
}

// temporary fix until uxcore2 version 2101.2.0 is in production
.pivot-container .ux-button:hover,
.pivot-container .ux-button:active,
.breadcrumb .ux-button:hover,
.breadcrumb .ux-button:active,
.backups-page-header .ux-button:hover,
.backups-page-header .ux-button:active,
.backup-cancel:hover,
.backup-cancel:active,
#backups-view-content:hover,
#backups-view-content:active {
  color: $ux-actionHovered-foregroundColor !important;
  background-color: transparent !important;
}

.dosetup-carousel-wrapper ul {
  width: auto;
  list-style-type: disc;
}

.dosetup-carousel-wrapper .carousel .carousel-arrow {
  background-color: transparent;
  bottom: auto;
  top: 50%;
}

.border-bottom {
  border-bottom: 1px solid $ux-control-borderColor;
}

.ws-setup-buttons {
  margin-top: 2.5em !important;
}

.last-radio .ux-custom-control .ux-custom-target {
  margin-bottom: 0 !important;
}

.carousel-slide {
  vertical-align: top;
}

.ux-dialog-title {
  margin-block-end: 0 !important;
}

// BEGIN UXCORE2200 STYLE OVERRIDES/UPDATES
.ux-disrupt-content .ux-dialog-content {
  margin-top: 1.5rem;
}

.ux-password-header {
  margin-bottom: 0 !important;
}

// to override margin: auto, which centers the modal in the view port (and thus size adjustments seem jarring)
.ux-disrupt-backdrop .ux-disrupt-content {
  margin-top: 5rem;
}

.ux-flyout {
  z-index: 10 !important;
}

// to override conflict with backups growl messages and header buttons in PCX theme
// to override conflict with all growl messages and modal backdrop
.ux-growl-shell {
  z-index: 1001;
}

.theme-godaddy-pxpro {
  // to override conflict with calendar component styling in PCX theme
  .ux-disrupt-backdrop {
    z-index: 100;
  }

  .ux-tabs-wiz-list .ux-tabs-wiz-step-complete > .ux-tabs-wiz-link:before {
    background: $ux-actionPrimaryChosen-backgroundColor;
  }
}

// to keep a consistent width on modals; v2200+ modals take width of content by default
.setup-step,
.ssl-modal-container,
.provision-wait-step,
.error-modal-container,
.status-modal-container,
.backups-modal-container,
.activate-modal-container,
.ticket-credentials-modal-container,
.plan-settings-modal-container {
  width: 100%;
  max-width: 40rem;

  @media (max-width: 768px) {
    max-width: 25rem;
  }

  @media (min-width: 1198px) {
    max-width: 45rem;
  }
}

// to make sure the overlay has enough room to display properly
.backups-modal-container {
  min-height: 12rem;

  .w-full .ux-dialog-content {
    margin: 0;
  }

  .ux-dialog-shell .ux-dialog-content {
    margin: 0;
  }
}

.backup-credentials-container {
  min-width: 40rem;

  @media (max-width: 768px) {
    min-width: 30rem;
    max-width: 100%;
  }
}

.activate-modal-container,
.ticket-credentials-modal-container {
  .ux-dialog-content {
    margin: 0;
  }

  h2.ux-text-title {
    font-size: 0.9em;
  }

  .ux-dialog-title {
    padding-inline-end: 0;
  }
}

.credentials-ticket-modal-container {
  @media (max-width: 768px) {
    width: 30rem;
    max-width: 100%;
  }
}

.theme-godaddy-pxpro {
  // to override conflicting styling in PCX theme
  .credentials-ticket-modal-container {
    width: 42rem;
    max-width: 100%;
  }
}

.overlay-message {
  vertical-align: middle;
}

.select-domain-container {
  .ux-search .form-group input {
    border-right: 1px solid lightgray;
  }
}

.sftp-form-container .ux-radio-shell {
  margin-right: 2rem !important;
}

// to override a PCX style that causes a tooltip's content to display behind a modal rather than on top of it
.tooltip {
  z-index: 5000 !important;
}

.dcc-wrapper .ux-disrupt-backdrop .ux-disrupt-content {
  margin-top: auto;
}
.appearance-none {
  appearance: none;
}

label[for='custom-domain'] {
  display: none !important;
}

.container.waf-activation-container {
  max-width: 1024px !important;
}

.waf-activation-wrapper {
  min-height: 250px;
}

.waf-setup-error {
  max-width: 600px;
}

.ux-disrupt-content .ux-dialog-content .ux-dialog-content {
  margin-top: 0.5rem;
}

.my-plans-container.ux-disrupt-backdrop {
  background: #fff;
}

.my-plans-container > :first-child {
  transform: none;
  filter: none;
}

.plans-logo-container {
  height: 50px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

@import 'helpers';
@import 'components/security-grade';
@import 'components/mssl-san-domain';
@import 'components/wss-setup';
@import 'components/firewall/screenshot';
@import 'components/firewall/widgets';
@import 'components/backups/';
@import 'components/monitoring/';
@import 'components/navigation-custom';

// Sucuri
@import '_sucuri.scss';

@import 'components/my-sites';
