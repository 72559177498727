@import '~@ux/intents/lib/index';

$uxcore-label: 'gd' !default; // gd (godaddy) vs pl (private label)
$uxcore-env: 'development' !default; // Environment
$uxcore-market: 'en-US' !default; // Which market
$uxcore-region: 'US' !default; // Which region
$uxcore-palette-build: false !default; // Is it a palette build (i.e. for a private label / alternate palette)

.security-grade {
  display: block;
  margin: auto;
  flex: 0 0 220px;
  padding-top: 3rem;
  position: relative;
  text-align: center;
  transform: translate3d(0, 0, 0);
  width: 220px;
  color: $ux-actionControl-foregroundColor; // Cash Black #111

  & > svg {
    left: 0;
    overflow: visible;
    position: absolute;
    top: 0;
  }

  .letter {
    font-size: 4rem;
    font-weight: var(--uxp-font-weight-bold);
    line-height: 1;
    margin-bottom: 0.25em;
    text-align: center;
    width: 100%;
    letter-spacing: -0.1em;

    @media (max-width: 968px) {
      font-size: 50px;
      margin-bottom: 0;
    }
  }

  .caption {
    font-weight: var(--uxp-font-weight-bold);
    line-height: 1;
    max-width: 100px;
    display: block;
    margin: auto;
    font-size: 16px;
  }

  &.disabled .letter,
  &.disabled .caption {
    color: rgb(207, 212, 214);
  }

  &.disabled .caption {
    font-weight: 600;
  }

  .svg-stroke-success {
    stroke: rgb(
      0,
      166,
      63
    ) !important; // #00A63F (should be $ux-feedbackHighContrastSuccess-feedbackColor)
  }

  .svg-fill-success {
    fill: rgb(
      0,
      166,
      63
    ) !important; // #00A63F (should be $ux-feedbackHighContrastSuccess-feedbackColor)
  }

  .svg-stroke-warning {
    stroke: rgb(
      255,
      160,
      0
    ) !important; // #FFA800 (should be $ux-feedbackHighContrastWarning-feedbackColor)
  }

  .svg-fill-warning {
    fill: rgb(
      255,
      160,
      0
    ) !important; // #FFA800 (should be $ux-feedbackHighContrastWarning-feedbackColor)
  }

  .svg-stroke-danger {
    stroke: rgb(
      219,
      24,
      2
    ) !important; // #DB1802 (should be $ux-feedbackHighContrastCritical-feedbackColor)
  }

  .svg-fill-danger {
    fill: rgb(
      219,
      24,
      2
    ) !important; // #DB1802 (should be $ux-feedbackHighContrastCritical-feedbackColor)
  }

  .svg-stroke-gray {
    stroke: rgb(
      186,
      192,
      195
    ) !important; // #BAC0C3 (should be intents.ux.feedbackHighContrastPassive.feedbackColor)
  }

  .svg-fill-gray {
    fill: rgb(
      186,
      192,
      195
    ) !important; // #BAC0C3 (should be intents.ux.feedbackHighContrastPassive.feedbackColor)
  }

  .score-unknown {
    padding-left: 1.5rem;
    color: rgb(207, 212, 214);
  }
}
