body.is-sucuri {
  background-color: $ux-box-backgroundColor;

  .bell-notification-wrapper,
  .privacy-link {
    display: none;
  }

  .my-sites-search-form {
    .ux-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .shopper-pin {
    display: none;
  }

  @media screen and (max-width: 991px) {
    margin-inline: 0;

    #hcs-header-container {
      background-color: var(--ux-9i7okd, green);
      padding: 0.5rem 1rem;
      color: #fff;

      svg {
        color: #fff;
      }

      .logo-anchor {
        padding: 0;
      }

      .horizontal-navigation {
        margin: 0;
      }
    }
  }
}
