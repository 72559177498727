@use '@ux/intents/lib/index' as ux;

.navigation-custom {
  .nav-link {
    background-color: transparent;
  }

  .nav-item {
    border-radius: calc(ux.$ux-box-borderRadius * 2);
    cursor: pointer;

    &.active {
      background-color: ux.$ux-navigateSecondaryChosen-backgroundColor;

      &:hover {
        background-color: ux.$ux-navigateSecondaryChosen-backgroundColor;
      }
    }

    &:hover {
      background-color: ux.$ux-navigateSecondaryHovered-backgroundColor;
    }
  }
}
