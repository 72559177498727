@import '~@ux/intents/lib/index';

.security-scan-details-info {
  padding: 2rem;
  background-color: $ux-controlCritical-backgroundColor;

  .security-details-link {
    position: relative;
    text-decoration: none;
  }

  .security-scan-details-footer {
    border-color: $ux-boxLowContrast-borderColor;
  }

  .security-scan-icon-vert {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: flipv;
    -ms-filter: 'FlipV';
  }
}

.scan-history-table {
  tr {
    td {
      &:first-child {
        width: 50%;
      }
    }
  }
}

.scan-results-accordion {
  .panel-button {
    padding: 0 1rem;

    .panel-title {
      padding: 1rem 0;
      font-size: $ux-text-fontSize;
    }
  }

  .panel-content {
    margin: 1rem;

    p,
    span {
      color: $ux-feedbackHighContrastPassive-feedbackColor;
    }
  }
}

.theme-godaddy-pxpro {
  .security-scan-details-info,
  .scanner-deactivated-box {
    background-color: $ux-boxLowContrast-backgroundColor;
  }
}

.scan-details-table {
  tr:first-child {
    td {
      border-top: 0;
    }
  }

  td:last-child {
    text-align: right;
  }
}

.ssl-details-table {
  td {
    border-top: 0;
    padding: 1rem 0.5rem;
  }

  td:first-child {
    white-space: nowrap;
  }

  td p {
    max-width: 700px;
    white-space: pre-line;
  }
}

.scan-changed {
  text-decoration: line-through;
  color: $ux-feedbackHighContrastPassive-feedbackColor;
}

.scan-uptime-info {
  font-size: 2.2em;
}

.checkmark-icon-banner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $ux-feedbackHighlight-feedbackColor;
}

.monitoring-overview-content {
  min-height: 100px;
}

.scanner-deactivated-box {
  padding: 2rem;
  background-color: $ux-controlCritical-backgroundColor;
}

.seals-opts-wrapper {
  max-width: 300px;
}

.seals-script {
  white-space: normal;
  max-width: 800px;
  margin: 0;
  padding: 1rem;
}

.stats-container {
  display: flex;
}

.stats-card {
  width: 100%;
  border-radius: 0.5em;
  background-color: white;
  margin: 0 10px 20px 0;
  padding: 10px;
  text-align: center;
  border: 2px solid $ux-boxLowContrast-borderColor;
  cursor: pointer;

  a {
    text-decoration: none !important;
  }

  &:hover {
    color: $ux-boxLowContrast-foregroundColor;
    text-decoration: none !important;
  }
}

.stats-card:last-child {
  margin-right: 0;
}

.stats-percent,
.statistics-caption {
  font-size: 0.8em;
}

.circle-small {
  height: 0.8em !important;
  width: 0.8em !important;
  margin-right: 5px;
}

.circle-noissues,
.stats-noissues {
  color: green;
  fill: green;
}

.circle-disabled,
.stats-disabled {
  color: gray;
  fill: gray;
}

.circle-error,
.stats-error {
  color: red;
  fill: red;
}

.circle-warning,
.stats-warning {
  color: orange;
  fill: orange;
}

.statistics-table tbody td,
.statistics-table thead th {
  text-align: center;
}

.statistics-table tbody td:first-child {
  text-align: left;
  font-size: 0.9em;
}

.statistics-table thead th,
.statistics-table tbody td:first-child {
  font-size: 0.9em;
}

.scanner-header-title:hover {
  cursor: pointer;
}

.ip-table {
  background-color: transparent; //override style-common.css
}

.scan-more-details-table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;

  tr {
    td:first-child {
      width: 10rem;
    }
  }
}
